<template>
    <div class="flex flex-col min-h-screen bg-gray-800 text-white">
      <!-- Header con nombre de usuario y botón de cerrar sesión -->
      <header class="flex justify-between items-center p-4 bg-gray-700">
        <button
          @click="handleLogout"
          class="flex items-center px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
        >
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="mr-2" />
          Cerrar sesión
        </button>
        <h2 v-if="employee" class="text-xl font-bold">{{ employee.nombre }}</h2>
      </header>
  
      <!-- Contenido principal -->
      <main class="flex-grow flex flex-col items-center justify-center p-4">
        <div v-if="loading" class="flex flex-col items-center justify-center">
          <svg
            class="animate-spin h-16 w-16 text-white mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p class="text-lg font-medium">Cargando...</p>
        </div>
  
        <div v-else>
          <!-- Botón de Play/Stop más grande -->
          <button
            :class="[
              'flex items-center justify-center w-64 h-64 rounded-full shadow-lg focus:outline-none focus:ring-4 focus:ring-opacity-50 transition-all duration-300 ease-in-out',
              marcaActiva
                ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                : 'bg-green-500 hover:bg-green-600 focus:ring-green-400',
            ]"
            @click="handleMarca"
            :disabled="loading"
          >
            <svg
              v-if="loading"
              class="animate-spin h-16 w-16 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <font-awesome-icon
              v-else
              :icon="marcaActiva ? 'stop' : 'play'"
              class="h-32 w-32"
            />
          </button>
  
          <!-- Estado de la marca -->
          <div class="mt-8 text-center">
            <p
              :class="[
                'text-2xl font-semibold',
                marcaActiva ? 'text-green-400' : 'text-red-400',
              ]"
            >
              {{ marcaActiva
                ? 'Marca Activa - Detener reloj'
                : 'Activar el reloj' }}
            </p>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2";
  import { API_URL } from "../globals";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import {
    faPlay,
    faStop,
    faSignOutAlt,
  } from "@fortawesome/free-solid-svg-icons";
  
  library.add(faPlay, faStop, faSignOutAlt);
  
  export default {
    name: "MarcasRegistros",
    components: {
      FontAwesomeIcon,
    },
    data() {
      return {
        loading: true,
        employee: null,
        marcaActiva: null,
      };
    },
    methods: {
      async checkMarca() {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(`${API_URL}/checkmarca`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          const { status, title, message, empleado, empleado_hora_id } =
            response.data;
  
          if (status === "success") {
            this.employee = empleado;
            this.marcaActiva = empleado_hora_id;
          } else {
            Swal.fire({
              icon: "error",
              title: title,
              text: message,
              background: "#333",
              color: "#fff",
            });
          }
        } catch (err) {
          console.error("Error al consultar el endpoint checkmarca:", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se pudo verificar la marca. Inténtalo de nuevo.",
            background: "#333",
            color: "#fff",
          });
        } finally {
          this.loading = false;
        }
      },
      async handleMarca() {
        this.loading = true;
        try {
          const token = localStorage.getItem("token");
  
          const response = await axios.post(`${API_URL}/marcas`, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          const { status, title, message } = response.data;
  
          if (status === "success") {
            Swal.fire({
              icon: "success",
              title: title,
              text: message,
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              background: "#333",
              color: "#fff",
            }).then(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              this.$router.push("/login");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Hubo un problema al registrar tu marca.",
              background: "#333",
              color: "#fff",
            });
          }
        } catch (err) {
          console.error("Error al registrar marca:", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se pudo registrar la marca. Inténtalo de nuevo.",
            background: "#333",
            color: "#fff",
          });
        } finally {
          this.loading = false;
        }
      },
      handleLogout() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.$router.push("/login");
      },
    },
    created() {
      this.checkMarca();
    },
  };
  </script>
  