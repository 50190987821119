import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../views/Login.vue';
import MarcasRegistros from '../views/Marcas.vue';

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/login',
    redirect: '/'
  },
  {
    path: '/marcas',
    name: 'MarcasRegistros',
    component: MarcasRegistros,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation Guard global
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'UserLogin' });
  } else {
    next();
  }
});

export default router;
