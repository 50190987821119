<template>
  <div class="flex min-h-screen bg-gray-900">
    <!-- Columna izquierda con imagen de fondo -->
    <div
      class="hidden lg:flex lg:w-1/2 bg-cover bg-center"
      :style="{ backgroundImage: `url(${IMAGES.LOGIN_BACKGROUND})` }"
    >
      <div class="w-full flex items-center justify-center bg-black bg-opacity-50">
        <!-- Espacio reservado para logo u otros elementos -->
      </div>
    </div>

    <!-- Columna derecha con formulario de login -->
    <div class="w-full lg:w-1/2 flex items-center justify-center bg-gray-800">
      <div class="w-full max-w-md p-8">
        <!-- Logo -->
        <div class="flex justify-center mb-4">
          <img
            :src="IMAGES.LOGO"
            alt="Prestigio Automotriz Logo"
            class="w-2/2 h-auto"
          />
        </div>
        <h2 class="text-3xl font-bold text-white mb-8 text-center">
          Iniciar sesión
        </h2>
        <form @submit.prevent="handleSubmit" class="space-y-6">
          <div>
            <label
              for="username"
              class="block text-sm font-medium text-gray-300"
            >
              Nombre de usuario
            </label>
            <input
              type="text"
              id="username"
              v-model="username"
              class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
              placeholder="Ingresa tu usuario"
            />
          </div>
          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-300"
            >
              Contraseña
            </label>
            <input
              type="password"
              id="password"
              v-model="password"
              class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
              placeholder="Ingresa tu contraseña"
            />
          </div>
          <div>
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              :disabled="loading"
            >
              <template v-if="loading">
                <svg
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Iniciando sesión...
              </template>
              <template v-else>
                Iniciar sesión
              </template>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL, IMAGES } from "../globals";

export default {
  name: "UserLogin",
  data() {
    return {
      IMAGES,
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      try {
        const response = await axios.post(`${API_URL}/login`, {
          username: this.username,
          password: this.password,
        });

        if (response.data.success) {
          const { token } = response.data;
          localStorage.setItem("token", token);

          Swal.fire({
            icon: "success",
            title: "Inicio de sesión exitoso",
            text: `Bienvenido`,
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            background: "#333",
            color: "#fff",
          });

          setTimeout(() => {
            this.$router.push("/marcas");
          }, 1500);
        } else {
          Swal.fire({
            icon: "error",
            title: "Credenciales inválidas",
            text: "Por favor, verifica tu nombre de usuario y contraseña.",
            background: "#333",
            color: "#fff",
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error al iniciar sesión. Inténtalo de nuevo.",
          background: "#333",
          color: "#fff",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
