<template>
  <div id="app">
    <!-- Agrega aquí tu header, navbar u otros componentes globales si los tienes -->
    
    <!-- Esta es la sección importante -->
    <router-view />
    
    <!-- Agrega aquí tu footer u otros componentes globales si los tienes -->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    // Aquí podrías importar componentes globales si es necesario
  }
};
</script>

<style>
/* Puedes agregar estilos globales aquí o importarlos desde un archivo CSS */
</style>
